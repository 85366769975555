import React from 'react'
import Layout from './src/components/Layout'

// This file is run in the browser. No ssr here.

/**
 * Gatsby Browser API: wrapPageElement
 *
 * Allow a plugin to wrap the page element.
 *
 * @param element
 * @param props
 * @returns {JSX.Element}
 */
export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>
