import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Navbar = () => (
    <div className='container d-none d-md-block'>
        <div className='container pt-3 position-absolute'>
            <Row className='justify-content-center'>
                <Col lg='10' md='12'>
                    <Link to='/'>
                        <StaticImage
                            src='../images/logos/sirumobile_logo.png'
                            height={50}
                            placeholder='blurred'
                            className='d-inline-block align-top'
                            alt='logo'
                        />
                    </Link>
                </Col>
            </Row>
        </div>
    </div>
)

export default Navbar
