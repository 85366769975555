import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

const NumberedRow = ({ children, number, numberContainerClassName, isLast = false }) => (
    <StyledRow className='justify-content-center'>
        <Col xl='2' lg='2' md='5' className='pt-4 pe-4 align-items-end d-lg-flex d-none flex-column'>
            <div
                className={`border rounded-circle round-number-box d-inline-block d-flex justify-content-center align-items-center mt-3 ${numberContainerClassName}`}>
                <div className='mb-1 ps-1 fs-3'>{number}.</div>
            </div>
            <div className='line-down-container d-flex justify-content-center mt-3 flex-grow-1'>
                <div className={`line-down h-100 ${isLast && 'border-0'}`} />
            </div>
        </Col>
        <Col xl='8' lg='9' md='12' className='pt-5 pb-3'>
            {children}
        </Col>
        <Col lg='1' />
    </StyledRow>
)

export default NumberedRow

const StyledRow = styled(Row)`
    .round-number-box {
        height: 4.5rem;
        width: 4.5rem;
    }
    .line-down-container {
        width: 4.5rem;
    }
    .line-down {
        width: 1px;
        border-left: 1px dashed var(--bs-gray-500);
    }
`
