// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payout-js": () => import("./../../../src/pages/payout.js" /* webpackChunkName: "component---src-pages-payout-js" */),
  "component---src-pages-paywithsiru-at-js": () => import("./../../../src/pages/paywithsiru/at.js" /* webpackChunkName: "component---src-pages-paywithsiru-at-js" */),
  "component---src-pages-paywithsiru-de-js": () => import("./../../../src/pages/paywithsiru/de.js" /* webpackChunkName: "component---src-pages-paywithsiru-de-js" */),
  "component---src-pages-paywithsiru-gb-js": () => import("./../../../src/pages/paywithsiru/gb.js" /* webpackChunkName: "component---src-pages-paywithsiru-gb-js" */),
  "component---src-pages-paywithsiru-ng-js": () => import("./../../../src/pages/paywithsiru/ng.js" /* webpackChunkName: "component---src-pages-paywithsiru-ng-js" */),
  "component---src-pages-paywithsiru-se-js": () => import("./../../../src/pages/paywithsiru/se.js" /* webpackChunkName: "component---src-pages-paywithsiru-se-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */)
}

