/**
 * Create an event to Google Analytics.
 *
 * @param {string} category
 * @param {string} action
 * @param {string|null} label
 * @param {boolean} isNonInteraction
 * @param {number|null} value
 * @param {string|boolean} transport
 */
export const gaCreateEvent = (
    category,
    action,
    label = null,
    isNonInteraction = false,
    value = null,
    transport = false
) => {
    if (value && !Number.isInteger(value)) {
        value = null
    }

    let eventData = {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        nonInteraction: isNonInteraction,
        eventValue: value,
    }

    // Specify event transport mechanism. 'xhr' is used by ga by default. Use 'beacon'
    // to track outbound links more reliably (if the browser supports it)
    if (transport) eventData.transport = transport

    if ('ga' in window) {
        const tracker = window.ga.getAll()[0]
        if (tracker) tracker.send('event', eventData)
    }
}

/**
 * Track anchor tag clicks.
 *
 * @param event
 */
export const gaLinkClick = event => {
    gaCreateEvent('Outbound Link', 'click', event.currentTarget.href)
}
