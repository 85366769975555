import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NumberedRow from './Layout/NumberedRow'
import { StaticImage } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const BookAMeeting = () => {
    // Note: on a StaticImage, src cannot be given as a prop.
    const staticImageProps = {
        placeholder: 'blurred',
        className: 'rounded-circle',
        width: 213,
    }

    return (
        <section id='book-a-meeting' className='py-5'>
            <Container>
                <NumberedRow number={5} isLast numberContainerClassName='bg-success text-white border-0'>
                    <h2 className='h1 mb-3'>Book a meeting</h2>

                    <p className='mb-4'>
                        Unlock your business's growth potential with Siru by contacting our dedicated team. Feel free to
                        book a meeting to learn more!
                    </p>
                    <Row className='text-center'>
                        <TeamMemberCol
                            name='Ilkka Seppänen'
                            image={
                                <StaticImage
                                    src='../images/bookameeting/ilkka-kuva.jpg'
                                    alt='Ilkka Seppänen'
                                    {...staticImageProps}
                                />
                            }
                            linkedInUrl='https://www.linkedin.com/in/ilkkaseppanen/'
                            calendlyUrl='https://calendly.com/ilkka-seppanen/meeting-with-ilkka-seppanen'
                        />

                        <TeamMemberCol
                            name='Timo Hakanpää'
                            image={
                                <StaticImage
                                    src='../images/bookameeting/timo-kuva.jpg'
                                    alt='Timo Hakanpää'
                                    {...staticImageProps}
                                />
                            }
                            linkedInUrl='https://www.linkedin.com/in/timohakanpaa/'
                            calendlyUrl='https://calendly.com/timo-hakanpaa'
                        />
                    </Row>
                </NumberedRow>
            </Container>
        </section>
    )
}

const TeamMemberCol = ({ image, name, linkedInUrl, calendlyUrl }) => {
    return (
        <Col sm='4' className='px-5 px-sm-3'>
            <div className='p-5 p-sm-4 pb-4'>{image}</div>
            <p className='h4 mb-3'>{name}</p>
            <div className='mb-3'>
                <a href={linkedInUrl} target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                        src='../images/logos/linkedin.png'
                        placeholder='blurred'
                        className='rounded-circle'
                        alt='logo'
                    />
                </a>
            </div>
            <div className='d-grid'>
                <a
                    href={calendlyUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='btn btn-success spaced-light rounded-0'>
                    Book a meeting
                </a>
            </div>
        </Col>
    )
}

export default BookAMeeting
