import React from 'react'
import { Container } from 'react-bootstrap'
import FaqItem from './FaqItem'
import NumberedRow from './Layout/NumberedRow'

const Faq = () => {
    return (
        <section id='faq' className='py-5'>
            <Container>
                <NumberedRow number={3}>
                    <h2 className='h1 mb-5'>Frequently asked questions</h2>
                    <FaqItem
                        question='What is DigitalDeposit™?'
                        answer="This payment service allows customers to easily pay and make deposits for digital services with no added hassle. With Siru, customers don't need to worry about entering credit card information, it's safe and simple to use."
                    />
                    <FaqItem
                        question='What are the benefits?'
                        answer='Instant mobile payments are an excellent way for businesses to increase end-user conversion. Our digital deposit customers stay with us for an average of 18+ months and our customer acquisition cost is affordable. Discover our pricing and start growing today!'
                    />
                    <FaqItem
                        question='What do we need to know about local regulation?'
                        answer={
                            <>
                                <p>Every country has its own demands for licensing.</p>
                                <ul>
                                    <li>Finland: All accepted</li>
                                    <li>Sweden: Local license requirement</li>
                                    <li>UK: Local license requirement + Payforit registration</li>
                                    <li>Germany: Local license requirement</li>
                                    <li>Austria: MGA licence</li>
                                </ul>
                            </>
                        }
                    />
                    <FaqItem
                        question='What does it cost?'
                        answer='We offer an affordable solution for merchants to integrate mobile payments into your business. For a low monthly fee starting from 50€ per country, merchants have access to our payment service, transaction processing, and support in multiple languages. End-users are responsible for any additional transaction fees. Our model has proven successful, having generated over 100 million EUR in value for merchants.'
                    />
                    <FaqItem
                        question='How to make the integration?'
                        answer={
                            <>
                                <p>
                                    We offer two options to start using our service: 1) Direct API integration or 2) PSP
                                    partners. The API integration process is a standard payment service integration
                                    which takes on average 1-5 days to complete. We also provide a sandbox environment
                                    for testing. To make the onboarding process easier, we assign a dedicated project
                                    manager to help you manage your backlog. We also provide direct access to our
                                    developer team through chat, Skype, or email.
                                </p>
                                <ul>
                                    <li>Bambora/Devcode</li>
                                    <li>EasyPaymentsGateway (EPG)</li>
                                    <li>ecoPayz</li>
                                    <li>MuchBetter</li>
                                    <li>ApcoPay</li>
                                    <li>Start2Pay</li>
                                </ul>
                            </>
                        }
                    />
                    <FaqItem
                        question='Terms & Conditions'
                        answer='Our Contracts are easy to understand. Enjoy weekly payment settlements in Finland, Sweden, and the UK, and monthly settlements in Austria and Germany. Plus, our service is fully GDPR Compliant.'
                    />
                </NumberedRow>
            </Container>
        </section>
    )
}

export default Faq
