import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
    return (
        <StyledFooter>
            <Container>
                <Row className='text-white py-5'>
                    <Col md='6' lg='3'>
                        {/* eslint-disable-next-line */}
                        <a href='https://www.sirumobile.com' target='_blank' rel='noopener'>
                            <StaticImage
                                src='../images/logos/sirumobile_logo_white.png'
                                height={90}
                                placeholder='blurred'
                                className='mb-3'
                                alt='logo'
                            />
                        </a>
                        <p>It's like an app, but better.</p>
                    </Col>
                    <Col md='6' lg='3' className='mb-4'>
                        <p className='mb-4'>
                            <strong>Company</strong>
                        </p>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/about'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                About us
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/careers'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                Careers
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/contact'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                Contact us
                            </a>
                        </p>
                    </Col>
                    <Col md='6' lg='3' className='mb-4'>
                        <p className='mb-4'>
                            <strong>Products</strong>
                        </p>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirupay'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                SiruPay
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/siruqr'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                SiruQR
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirukyc'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                SiruKYC
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirusms'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                SiruSMS
                            </a>
                        </p>
                    </Col>
                    <Col md='6' lg='3' className='mb-4'>
                        <p className='mb-4'>
                            <strong>Learn more</strong>
                        </p>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/faq'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                FAQ
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirupay/demo'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                Demo
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/terms-and-conditions'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                Terms of use
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/privacy-policy'
                                target='_blank'
                                rel='noopener'
                                className='d-inline-block mb-3'>
                                Privacy policy
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row className='pb-5'>
                    <Col md='6' className='text-secondary mb-3'>
                        Copyright {new Date().getFullYear()} &copy; sirumobile.com
                    </Col>
                    <Col md='3'>
                        {/* eslint-disable-next-line */}
                        <a
                            href='https://www.sirumobile.com/business/support'
                            target='_blank'
                            rel='noopener'
                            className='d-inline-block mb-3'>
                            Merchant support
                        </a>
                    </Col>
                    <Col md='2'>
                        {/* eslint-disable-next-line */}
                        <a
                            href='https://www.sirumobile.com/developers'
                            target='_blank'
                            rel='noopener'
                            className='d-inline-block mb-3'>
                            Developers
                        </a>
                    </Col>
                </Row>
            </Container>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    background-color: var(--bs-dark);

    a {
        color: var(--bs-gray);
    }

    a:hover {
        color: var(--bs-light);
        text-decoration: none;
    }
`

export default Footer
