import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  a {
    text-decoration: none;
  }
  
  .btn {
    box-shadow: 0 0 27px 0 rgba(0,0,0,0.12);
  }
  
  .btn-link {
    box-shadow: none;
  }
  
  h1 {
    font-weight: bold;
  }
  
  .jumbotron {
    background-color: transparent;
  }
  
  .dark-background-overlay {
    background-color: rgba(0, 0, 0, 0.24) !important;
    opacity: 1 !important;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s !important;
  }

  .darker-background-overlay {
    background-color: rgba(0, 0, 0, 0.44) !important; 
    opacity: 1 !important;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s !important;
  }
  
  .bottom-left-drop-shadow {
    box-shadow: -67px 35px 25px 0 rgba(0, 0, 0, 0.11);
  }
  
  form .form-control, form .btn {
    font-size: 1.2rem;
    letter-spacing: 1.5px;
  }
  
  .full-screen-demo-section {
    height: clamp(29em, 100vh, 44em);
    overflow: hidden;
  }

  .full-screen-demo {
    min-height: clamp(35em, 117vh, 50em);
  }

  .z-index-n1 {
    z-index: -1;
  }
`

export default GlobalStyles
