import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import styled from 'styled-components'
import { gaCreateEvent } from '../utilities/Analytics'

/**
 * @param {string} question
 * @param {string|JSX.Element} answer
 * @param {string|undefined} slug to be passed as id (for example, for anchoring)
 * @returns {JSX.Element}
 * @constructor
 */
const FaqItem = ({ question, answer, slug = undefined }) => {
    const onClickHandler = () => gaCreateEvent('Click', 'Faq item clicked', slug || question)

    return (
        <StyledAccordion className='mb-4' {...schemaQuestionAttrs} id={slug}>
            <Card className='px-4 pb-0 shadow-sm'>
                <Accordion.Header eventkey='0' itemProp='name' onClick={onClickHandler}>
                    {question}
                </Accordion.Header>
            </Card>
            <Accordion.Body eventkey='0' {...schemaAnswerAttrs}>
                <div className='py-1 px-4'>{answer}</div>
            </Accordion.Body>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)`
    .card {
        border-radius: 2rem !important;
        border: 1px solid var(--bs-gray) !important;
    }
    .card:hover {
        border-color: var(--bs-green) !important;
    }

    .accordion-button:hover {
        color: var(--bs-green);
    }
    .accordion-button:focus {
        box-shadow: none;
        color: var(--bs-black);
    }
    .accordion-button:not(.collapsed) {
        color: var(--bs-black);
        background-color: transparent;
    }
`

const schemaQuestionAttrs = {
    itemScope: '',
    itemProp: 'mainEntity',
    itemType: 'https://schema.org/Question',
}

const schemaAnswerAttrs = {
    itemScope: '',
    itemProp: 'acceptedAnswer',
    itemType: 'https://schema.org/Answer',
}

export default FaqItem
