import React from 'react'
import { Card, Container } from 'react-bootstrap'
import NumberedRow from './Layout/NumberedRow'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = () => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "contact-section-bg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 3840
                        height: 500
                        placeholder: BLURRED
                        quality: 80
                        transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                    )
                }
            }
        }
    `)

    const bgImage = getImage(image)

    return (
        <section id='contact' className=' darker-background-overlay position-relative'>
            <div className='d-flex'>
                <Container className='py-5'>
                    <NumberedRow number={4} numberContainerClassName='bg-success text-white border-0'>
                        <Card body className='border-0 py-5'>
                            <StyledIframe
                                className='w-100'
                                src='https://webforms.pipedrive.com/f/mUJSla9pYNevjXBwsOMKpvE6sdQjh9HExX7RSejs1xLvIPJansKYRnUfoJymiIP?embeded=1&uuid=id7mjpl'
                            />
                        </Card>
                    </NumberedRow>
                </Container>
                <GatsbyImage
                    className='z-index-n1 position-absolute w-100 h-100'
                    alt=''
                    image={bgImage}
                    role='presentation'
                    placeholder='blurred'
                    loading='lazy'
                />
            </div>
        </section>
    )
}

const StyledIframe = styled.iframe`
    height: 705px;
`

export default ContactForm
